import BaseControl from '../base-control/base-control'
import $ from 'jquery'

import '../style/background-control.scss'
import Layout from '../templates/background-readonly-control.tpl'

class BackgroundControl extends BaseControl {
  constructor (controller, model) {
    super(controller, () => Layout, model, 'background')
    this.lazyImageSelector = '.kv-background-inner, video'
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    // Fix for iOS which doesn't support fixed
    if (data.fixed === true) {
      const iOS =
        typeof window !== 'undefined' &&
        window.navigator &&
        !!window.navigator.platform &&
        /iPad|iPhone|iPod/.test(window.navigator.platform)
      if (iOS === true) {
        element.querySelector('.kv-background-inner').style.backgroundAttachment = ''
      }
    }

    if (data.parallax === true) {
      setTimeout(() => {
        const $parallax = $('.kv-parallax-container', element)
        const total = window.innerHeight

        function parallax () {
          const scrolled = element.getBoundingClientRect().top

          const movement = (scrolled / total) * (0.2 / 1.4)
          $parallax.css('transform', `scale(1.4) translate3d(0, ${movement * 100}%, 0)`)
        }
        if (element.scrollFunction) {
          window.removeEventListener(element.scrollFunction)
        }
        element.scrollFunction = (e) => {
          parallax()
        }
        window.addEventListener('scroll', element.scrollFunction)
      }, 0)
    }

    if (data.contain === true) {
      element.querySelector('.kv-background-inner').style.backgroundSize = 'contain'
    }
  }

  getChildControls () {}
}

export default BackgroundControl
