import GlobalProps from 'editor/controls/handlebars/global-props'

const fields = {
  firstName: 'fieldFirstName',
  lastName: 'fieldLastName',
  subject: 'fieldSubject',
  message: 'fieldMessage',
  email: 'fieldEmail',
  phone: 'fieldPhone',
  date: 'fieldDate',
  address: 'fieldAddress',
  time: 'fieldTime',
  city: 'fieldCity',
  postalCode: 'fieldPostalCode',
  state: 'fieldState',
  subscribe: 'fieldSubscribe',
  people: 'fieldPeopleAmount'
}

const contactHelper = (fieldsList, requestedFields, classList, data) => {
  const buildWrapper = type => {
    if (
      fieldsList &&
      fieldsList.formOptions &&
      fieldsList.formOptions[type] &&
      fieldsList.formOptions[type].toggle &&
      fieldsList.formOptions[type].toggle === true
    ) {
      const translation = fieldsList.formOptions[type].translation

      if (type === 'fieldMessage') {
        return `<div class="${type}-container">
        <label>${translation}</label>
        <textarea
          class="form-control"
          id="${type}"
          placeholder=""
          data-nameLabel="${translation}"
          required=""></textarea>
        <div class="error-container"></div>
      </div>`
      } else {
        return `<div class="form-group ${type}-container ${classList}">
          <label>${translation}</label>
          <input
            class="form-control"
            id="${type}"
            type="${type === 'fieldDate' ? 'date' : 'text'}"
            placeholder=""
            required=""
            data-nameLabel="${translation}"
            aria-invalid="false"
          />
          <div class="error-container"></div>
        </div>`
      }
    } else {
      return ''
    }
  }

  const buildSubscribeElement = key => {
    const type = 'fieldSubscribe'
    const isToggle = key === 'subscribeToggle'
    const translationKey = `editorTemplates.features.subscription.${key}`
    const translation = GlobalProps.localize(translationKey)

    const defaulValue = !isToggle
    return `<div class="${type}-container">
      <label>
        <input
          type="checkbox"
          id="${type}"
          value=${defaulValue}
          style="${isToggle ? '' : 'display: none'}"
          data-nameLabel="${translation}"
        />
        ${translation}
      </label>
    </div>`
  }

  let templateString = ''

  // Generate an element with the section id
  if (requestedFields.length === 1 && requestedFields[0] === 'sectionId') {
    return `<div class='hidden-form-data' data-sectionid='${data.data.root._sectionId}'></div>`
  }

  // Generate the success message element
  if (requestedFields.length === 1 && requestedFields[0] === 'successMessage') {
    // Promo codes are available in the promotion sections
    if (fieldsList.promoCode) {
      return `<div class='success-message-wrapper'>
        <div class='section-icon on_card icon' data-type='icon'>
          <i class='fa fa-check-circle' aria-hidden='true'></i>
        </div>
        <h3>
          ${fieldsList.formOptions.successTitle}
        </h3>
        ${fieldsList.formOptions.successMessage}
        <h3 class='promo-code'>
          ${fieldsList.promoCode}
        </h3>
        <p class='footnote'>
          ${fieldsList.formOptions.successFootnote}
        </p>
      </div>`
    } else if (fieldsList.formOptions && fieldsList.formOptions.hasOwnProperty('successMessage')) {
      return `<div class='success-message-wrapper'>
        <div class='section-icon on_card icon' data-type='icon'>
          <i class='fa fa-check-circle' aria-hidden='true'></i>
        </div>
        <h3>${(fieldsList.formOptions && fieldsList.formOptions.successTitle) || GlobalProps.localize('modules.formeditor.formOptions.messageSent')}</h3>
        ${fieldsList.formOptions.successMessage}
      </div>`
    } else {
      return `<div class='success-message-wrapper'>
        <div class='section-icon on_card icon' data-type='icon'>
          <i class='fa fa-check-circle' aria-hidden='true'></i>
        </div>
        <h3>${(fieldsList.formOptions && fieldsList.formOptions.successTitle) || GlobalProps.localize('modules.formeditor.formOptions.messageSent')}</h3>
      </div>`
    }
  }

  // success close
  if (requestedFields.length === 1 && requestedFields[0] === 'successCloseButton') {
    return `<div class='success-close'>
        <i class="fa fa-close"></i>
      </div>`
  }

  // Otherwise generate the other types
  requestedFields.forEach(item => {
    if (item === 'subscribe' || item === 'subscribeToggle') {
      templateString += buildSubscribeElement(item)
    } else if (fields[item]) {
      templateString += buildWrapper(fields[item])
    }
  })

  return templateString
}

export default contactHelper
