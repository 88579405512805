import each from 'lodash/each'
import map from 'lodash/map'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import { TOGGLE_CAT } from 'shared/consts'
import LogError from '../utils/log-error'
import { merge } from 'designer/store/helpers/merge'
import { FEATURES } from 'shared/account-features'

////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
/////////

const DEVELOP_MODE_CATEGORY = 'developer'
let layoutInstance = null

let layoutsFromDeveloperMode = null
let featuresFromDeveloperMode = null
let pageTypesFromDeveloperMode = null
let propertiesFromDeveloperMode = null

class Layouts {
  static getLayoutObject () {
    return layoutInstance
  }

  static setLayoutObject (instance) {
    layoutInstance = instance
    if (layoutsFromDeveloperMode) {
      instance.updateLayouts(layoutsFromDeveloperMode)
    }
    if (featuresFromDeveloperMode) {
      instance.updateFeatures(featuresFromDeveloperMode)
    }
    if (pageTypesFromDeveloperMode) {
      instance.updatePageTypes(pageTypesFromDeveloperMode)
    }
    if (propertiesFromDeveloperMode) {
      instance.updateProperties(propertiesFromDeveloperMode)
    }
  }

  static initialize (layoutData, canThrowError = false, canReturnEmptyLayout = false) {
    Layouts.setLayoutObject(new Layouts(layoutData, canThrowError, canReturnEmptyLayout))
  }

  static setFeatureDataForDeveloperMode (newData) {
    featuresFromDeveloperMode = newData
    if (layoutInstance) {
      layoutInstance.updateFeatures(newData)
    }
  }

  static setLayoutDataForDeveloperMode (newData) {
    layoutsFromDeveloperMode = newData
    return layoutInstance ? layoutInstance.updateLayouts(newData) : {}
  }

  static setPageTypesDataForDeveloperMode (newData) {
    pageTypesFromDeveloperMode = newData
    if (layoutInstance) {
      layoutInstance.updatePageTypes(newData)
    }
  }

  static setPropertiesDataForDeveloperMode (newData) {
    propertiesFromDeveloperMode = newData
    if (layoutInstance) {
      layoutInstance.updateProperties(newData)
    }
  }

  constructor (layoutData, canThrowError = false, canReturnEmptyLayout = false) {
    this.layoutsById = {}
    this.layouts = {}
    this.categories = {}
    // canThrowError is used for the layout maker
    this.canThrowError = canThrowError
    // canReturnEmptyLayout is used for publish / preview
    this.canReturnEmptyLayout = canReturnEmptyLayout

    map(layoutData.features || {}, feat => {
      feat.settings = typeof feat.settings === 'string' ? JSON.parse(feat.settings) : feat.settings
    })

    const data = layoutData.layouts
    this.features = layoutData.features

    this.pageTypes = {}
    const pageTypes = layoutData.page_types || layoutData.pageTypes
    each(pageTypes, (pages, folder) =>
      each(pages, (content, pageTypeName) => {
        this.pageTypes[`${folder}/${pageTypeName}`] = content
      })
    )
    this.properties = layoutData.properties || {}

    // parse layouts, convert array to name stored item
    for (const type in data) {
      const layouts = data[type]
      this.layouts[type] = {}
      each(layouts, instance => {
        // check if layout allready exists, then skip
        if (this.layouts[type][instance.name] != null) {
          return
        }
        this.layouts[type][instance.name] = instance
        instance.path = `${type}/${instance.name}`
        if (!instance.metadata) {
          instance.metadata = {}
        }
        if (!instance.fields) {
          instance.fields = []
        }

        if (canThrowError && !instance.metadata.id) {
          console.error(`${instance.path} has no id`)
        }

        instance.id = instance.metadata.id
        this.layoutsById[instance.id] = instance

        this.registerCategories(instance.metadata.categories, instance.path, false)
      })
    }
  }

  registerCategories (categories, path, checkExists) {
    // register categories
    each(categories, cat => {
      cat = cat.toLowerCase()
      if (!this.categories[cat]) {
        this.categories[cat] = []
      }
      if (!checkExists || this.categories[cat].indexOf(path) < 0) {
        this.categories[cat].push(path)
      }
    })
  }

  updatePageTypes (array) {
    array.forEach(pageType => {
      this.pageTypes[pageType.name] = pageType.content
    })
  }

  updateProperties (array) {
    array.forEach(pageType => {
      this.properties[pageType.name] = pageType.content
    })
  }

  updateLayouts (data) {
    const updateResult = {}

    const layouts = data.categories
    each(layouts, (part1, layoutType) => {
      each(part1, (layout, layoutName) => {
        // prefix so you can't override layouts we already have
        const convertPath = path => path.toLowerCase()

        layoutType = convertPath(layoutType)
        if (!this.layouts[layoutType]) {
          this.layouts[layoutType] = {}
        }
        const layoutTypeObj = this.layouts[layoutType]

        const path = `${layoutType}/${layoutName}`

        const instance = {
          ...layoutTypeObj[layoutName], // merge earlier stuff
          ...layout,
          path,
          id: layout.overrideId || `devid-${path.replace('/', '-')}`
        }
        delete instance.properties

        updateResult[instance.id] = {
          hasCss: layout.css || layout.style_variables,
          hasHtml: layout.html || layout.html_templates
        }

        if (!instance.fields) {
          instance.fields = []
        }
        instance.compiledLayouts = null // reset handlebars

        const metadata = instance.metadata
        if (metadata.parents) {
          metadata.parents = !Array.isArray(metadata.parents) ? null : metadata.parents.map(convertPath)
          if (metadata.parents === null) {
            console.error('metadata: parents should be an array')
          }
        }

        layoutTypeObj[layoutName] = instance
        this.layoutsById[instance.id] = instance

        metadata.categories = metadata.categories || [DEVELOP_MODE_CATEGORY]

        this.registerCategories(metadata.categories, path, true)
      })
    })
    return updateResult
  }

  updateFeatures (featureUpdates) {
    featureUpdates.features.forEach(feat => {
      if (feat.name) {
        // merge
        this.features[feat.name] = {
          ...this.features[feat.name],
          ...feat
        }
      }
    })

    each(this.features, feature => {
      if (feature.url) {
        feature.scriptUrl = feature.url.replace('{timestamp}', featureUpdates.timestamp)
      }
    })
  }

  getLayoutsByType (layoutType, filter) {
    let layouts = this.layouts[layoutType]
    if (!layouts) {
      throw new Error(`layout folder not found: ---[${layoutType}]---`)
    }

    if (filter && filter !== '*') {
      const start = filter.substr(0, filter.indexOf('*'))
      const filteredResult = {}
      for (const key in layouts) {
        if (key.indexOf(start) === 0) {
          filteredResult[key] = layouts[key]
        }
      }
      layouts = filteredResult
    }

    return layouts
  }

  getDebugLayout (path, html) {
    return {
      id: path,
      path,
      html,
      fields: [],
      css: '.text1 { color: $text1; }',
      metadata: { id: path, demodata: {} },
      style_variables: [
        {
          style_declaration: 'color',
          style_value: '$text1',
          selector_text: `.${path} .text1`,
          variable_name: '$text1'
        }
      ]
    }
  }

  getLayout (layoutType, name) {
    const layouts = this.getLayoutsByType(layoutType)
    const layout = layouts[name]
    if (!layout) {
      const path = `${layoutType}/${name}`
      const errorMsg = `Layout not found: [${path}]`

      if (this.canThrowError) {
        throw new Error(errorMsg)
      }

      LogError.log(errorMsg)

      return this.getDebugLayout(path, this.canReturnEmptyLayout ? `<div data-error="${errorMsg}"></div>` : `<h2 class="text1">${errorMsg}</h2>`)
    }
    return layout
  }

  // fill the properties for the layouts/feature from the global properties, merge if needed. Also group in categories and order
  convertProps (properties) {
    function mergeProp (def, prop) {
      if (!def) {
        return prop
      }
      return merge(def, prop)
    }

    function addProp (newProps, propName, prop) {
      if (prop) {
        prop.name = propName
        if (prop.matchRegex && !prop.matchingRegex) {
          prop.matchingRegex = new RegExp(prop.matchRegex)
          prop.matchingFields = []
        }

        const category = prop.category || TOGGLE_CAT
        if (!newProps[category]) {
          newProps[category] = [{ ...prop }]
        } else {
          newProps[category].push({ ...prop })
        }
      }
    }

    const newProps = {}

    if (properties) {
      if (properties.generic) {
        properties.generic.forEach(propName => {
          addProp(newProps, propName, this.properties[propName])
        })
      }
      if (properties.custom) {
        Object.entries(properties.custom).forEach(([propName, prop]) => {
          addProp(newProps, propName, mergeProp(this.properties[propName], prop))
        })
      }

      // sort all the properties after merging
      Object.values(newProps).forEach(array => array.sort((a, b) => (a.order || 0) - (b.order || 0)))
    }
    return newProps
  }

  // convert properties, only when we haven't done it yet, this code is triggert jit before the properties are needed (from site-create and property-manager)
  convertPropsForLayout (layout) {
    if (!layout.properties) {
      layout.properties = this.convertProps(layout.metadata.properties)
    }
    return layout
  }

  getById (id) {
    const result = this.layoutsById[id]
    if (!result) {
      if (id === 'debug') {
        return this.getDebugLayout(id, '<div class="text1"><h1 class="m-5">{{title}}</h1><div class="m-5">{{text "description" "p"}}</div></div>')
      }
      if (id && id.indexOf('devid-') === 0) {
        console.error(`layout not found: [${id}]\n---This layout was served from the devkit but can't be found atm---`)
        return null
      }
      throw new Error(`layout not found: ${id}`)
    }
    return this.convertPropsForLayout(result)
  }

  get (path) {
    return this.convertPropsForLayout(this.getLayoutByPath(null, path)[0])
  }

  getLayoutByPath (layoutType, path) {
    let pathSplit
    if (path.indexOf('./') === 0) {
      pathSplit = [layoutType, path.substr(2)]
    } else {
      if (path.indexOf('../') === 0) {
        path = path.substr(3)
      }
      pathSplit = path.split('/')

      if (path === 'debug') {
        return [this.getDebugLayout(path, '<h1 class="text1">{{title}}</h1><p>{{description}}</p>')]
      }

      if (pathSplit.length !== 2 && pathSplit.length !== 3) {
        throw new Error(`invalid layout path: ${path}`)
      }
    }

    if (pathSplit[1].indexOf('*') >= 0) {
      const layouts = this.getLayoutsByType(pathSplit[0], pathSplit[1])
      return map(layouts, i => i)
    }
    const layout = this.getLayout(pathSplit[0], pathSplit[1])
    return [layout]
  }

  getLayoutsForCategory (categoryName, binding = null) {
    categoryName = (categoryName || '').toLowerCase()
    if (!this.categories[categoryName]) {
      if (categoryName === 'debug') {
        return this.getLayoutByPath(null, categoryName)
      }
      return []
    }
    let layouts = this.getLayoutsToSwitch(this.categories[categoryName])

    // TODO: @pkiers @dvandervlag Think of a mechanism to handle there sorts of exceptions..
    //  Maybe add a requiredProperties attribute to the layouts (array), in which we define properties that a layout requires on, and at least one of them should be toggled on in order to show the layout..
    // TODO: @dvandervlag add this to editor-core.
    if (binding && categoryName === 'text') {
      const listItemsOnly = !!(!binding._toggle?.title && !binding._toggle?.subtitle && !binding._toggle?.description)
      if (listItemsOnly) {
        layouts = layouts.filter(layout => {
          return layout.metadata.hasOwnProperty('parents')
        })
      }
    }
    const sortByName = (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    const sortByOrderId = (a, b) => (a.metadata.order < b.metadata.order ? -1 : a.metadata.order > b.metadata.order ? 1 : 0)
    return layouts.sort(sortByName).sort(sortByOrderId)
  }

  getLayoutsToSwitch (layouts) {
    const array = map(layouts, layoutPath => {
      const layout = this.getLayoutByPath(null, layoutPath)

      if (DEBUG_LAYOUTS) {
        return filter(layout, i => !i.metadata.isParent && !i.metadata.isDeprecated)
      } else {
        return filter(layout, i => !i.metadata.isParent && !i.metadata.isDeprecated && i.metadata.debug !== true)
      }
    })
    return flatten(array)
  }

  getParentLayouts (layout) {
    if (layout.metadata && layout.metadata.parents) {
      return flatten(layout.metadata.parents.map(i => this.getLayoutByPath(layout.layoutType, i)))
    }
    return null
  }

  getLayoutForNewSection (canAddLayout) {
    const layouts = []

    const store = Store().getState()
    const { accountFeatures } = store.api
    const { accountType } = store.auth

    each(this.categories, (category, index) => {
      if (index === 'headers' || index === 'footers') {
        return
      }

      if (category.length < 1) {
        return
      }

      const categoryLayouts = this.getLayoutsForCategory(index).filter(layout => {
        const doesNotHaveFeature = layout.metadata.accountFeatureRequirement && accountFeatures[layout.metadata.accountFeatureRequirement] !== true

        const isStoreLayout = layout.metadata.accountFeatureRequirement === FEATURES.STORE
        const isBlogLayout = layout.metadata.accountFeatureRequirement === FEATURES.BLOG

        if (isStoreLayout) {
          const isValidAccountType = !isSP(accountType) && !isCTCT(accountType)

          if (!isHostedBrand() && (doesNotHaveFeature || !isValidAccountType)) {
            return false
          }
        } else if (isBlogLayout) {
          if (LimitationsManager.isBlogLimited()) {
            return false
          }
        } else {
          if (doesNotHaveFeature) {
            return false
          }
        }
        return layout.metadata.canAddAsSection !== false && canAddLayout(layout)
      })

      each(categoryLayouts, (layout, i) => {
        layouts.push({ layout, key: index })
      })
    })
    return layouts
  }

  getGroupedLayouts () {
    const layouts = this.getLayoutForNewSection()
    const groups = []

    layouts.forEach((item, i) => {
      const metadata = item.layout.metadata
      const groupName = metadata.group ? metadata.group.name : item.key

      // Group
      let group = groups.find(g => g.name === groupName)
      if (!group) {
        group = {
          name: groupName,
          items: [],
          ...item
        }
        groups.push(group)
      }

      // Subgroup
      const subGroupName = metadata.group && metadata.group.subgroup ? metadata.group.subgroup : 'default'
      let subgroup = group.items.find(s => s.name === subGroupName)
      if (!subgroup) {
        subgroup = {
          name: subGroupName,
          key: item.key,
          order: metadata.group && metadata.group.subgroupIndex ? metadata.group.subgroupIndex : 0,
          items: []
        }
        group.items.push(subgroup)
      }

      // Add layout
      subgroup.items.push(item)
    })

    return groups
  }

  getFeatureByName (name) {
    if (this.features.hasOwnProperty(name)) {
      const feature = this.features[name]
      if (!feature.properties) {
        feature.properties = this.convertProps(feature.settings.properties)
      }
      return feature
    }
    return null
  }
}

export default Layouts
